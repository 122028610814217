import React from 'react';
import { Box } from '@mui/material';

const Footer = () => {

  const linkStyle = {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: 'inherit',
    display: 'block',
    padding: '8px 0',
    transition: 'color 0.3s',
  };
  // const hoverLinkStyle = {
  //   ...linkStyle,
  //   '&:hover': {
  //     color: 'red',             // Red color on hover
  //   },
  // };

  return (
    <footer id="footer" className="footer dark-background ">
      <div className="container">
        <h3 className="sitename">VisitLamu</h3>
        <p>Visit Lamu is a premier Tour & Travel Company nestled in the heart of Lamu, Kenya. We specialize in curating unforgettable travel experiences for adventurers from across the globe, allowing them to discover and explore Lamu and its surrounding areas.
          <br />
        </p>
        <span>Location map</span>
          <div className='container-fluid mb-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.6846297735697!2d40.9031052!3d-2.2710022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x181719a8c39af7a1%3A0x2eda6a2dac052c4c!2sLamu%20Dhow%20Cruise!5e0!3m2!1sen!2ske!4v1731432814340!5m2!1sen!2ske"
              width="70%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
        


        <div className='row'>
          <div className='col' style={{ listStyle: 'none', textDecoration: 'none', color: 'white', textAlign: 'left' }}>
            <p>Common Links:</p>

            <li><Box component="a" href="/" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Home</Box></li>
            <li><Box component="a" href="/lamu" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Lamu</Box></li>
            <li><Box component="a" href="/about" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>About</Box></li>
            <li><Box component="a" href="/services" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Services</Box></li>
            <li><Box component="a" href="/excursions" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Excursions</Box></li>
            <li><Box component="a" href="/contact" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Contact Us</Box></li>
          </div>

          <div className="col contact-info">
            <p>Connect with us:</p>
            <p>
              <i className="bi bi-mailbox" style={{ fontSize: "2rem" }}></i>
              <a href="mailto:info@visitlamu.co">info@visitlamu.co</a>
              <br />
              <i className="bi bi-telephone" style={{ fontSize: "2rem" }}></i>
              <a href="tel:+254781888999">+254 781 888 999</a>
            </p>

            <p>Pament Methods:</p>
            <p>
              <i className="bi bi-paypal" style={{ fontSize: "2rem" }}></i>
              <span>Paypal</span>
              <br />
              <i className="bi bi-cash" style={{ fontSize: "2rem" }}></i>
              <span>Mpesa</span>
            </p>

          </div>
          
        </div>
        <div className="social-links d-flex justify-content-center">
          <a target='_blank' rel="noreferrer" href="https://wa.me/254781888999"><i className="bi bi-whatsapp"></i></a>
          <a target='_blank' rel="noreferrer" href="https://x.com/visit_lamu?s=11"><i className="bi bi-twitter"></i></a>
          <a target='_blank' rel="noreferrer" href="https://www.facebook.com/share/1EupMVQDL2/?mibextid=LQQJ4d"><i className="bi bi-facebook"></i></a>
          <a target='_blank' rel="noreferrer" href="https://www.instagram.com/visit_lamu?igsh=MXJobDdjNDcyMnNwbQ%3D%3D&utm_source=qr"><i className="bi bi-instagram"></i></a>
          <a target='_blank' rel="noreferrer" href="https://www.tiktok.com/@visit_lamu?_t=8rDyko6WNLb&_r=1"><i className="bi bi-tiktok"></i></a>
        </div>

        <div className="footer-bottom">
          <div className="copyright">
            <span>&copy; </span><strong className="sitename">Visit Lamu</strong><span> All Rights Reserved</span>
          </div>
          <div className="credits">
            Designed by <a target='_blank' href="https://meneja.co.ke/" rel="noopener noreferrer">Kencert</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
