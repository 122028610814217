import React, { useEffect } from 'react';
// import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import default styles

const MainContent = () => {
    useEffect(() => {
        // Insert the app.js logic here or import it if it's in a separate file
        let nextDom = document.getElementById('next');
        let prevDom = document.getElementById('prev');

        let carouselDom = document.querySelector('.carousel');
        let SliderDom = carouselDom.querySelector('.carousel .list');
        let thumbnailBorderDom = document.querySelector('.carousel .thumbnail');
        let thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');
        // let timeDom = document.querySelector('.carousel .time');

        thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
        let timeRunning = 2000;
        let timeAutoNext = 3000;

        nextDom.onclick = function () {
            showSlider('next');
        };

        prevDom.onclick = function () {
            showSlider('prev');
        };

        let runTimeOut;
        let runNextAuto = setTimeout(() => {
            nextDom.click();
        }, timeAutoNext);

        function showSlider(type) {
            let SliderItemsDom = SliderDom.querySelectorAll('.carousel .list .item');
            let thumbnailItemsDom = document.querySelectorAll('.carousel .thumbnail .item');

            if (type === 'next') {
                SliderDom.appendChild(SliderItemsDom[0]);
                thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
                carouselDom.classList.add('next');
            } else {
                SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
                thumbnailBorderDom.prepend(thumbnailItemsDom[thumbnailItemsDom.length - 1]);
                carouselDom.classList.add('prev');
            }
            clearTimeout(runTimeOut);
            runTimeOut = setTimeout(() => {
                carouselDom.classList.remove('next');
                carouselDom.classList.remove('prev');
            }, timeRunning);

            clearTimeout(runNextAuto);
            runNextAuto = setTimeout(() => {
                nextDom.click();
            }, timeAutoNext);
        }
    }, []);

    return (
        <main className="main">
            <div className="carousel">
                {/* Slider Container */}
                <div className="list">
                    <div className="item">
                        <img src="background.jpg" alt="Slide 1" />
                        <div className="content">
                            <div className="author">VISIT LAMU</div>
                            <div className="title">Travel, Discover,</div>
                            <div className="topic">Explore, Adventure</div>
                            {/* <div className="des">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus.
                            </div> */}
                            {/* <div className="buttons"> */}
                            {/* <button>SEE MORE</button> */}
                            {/* <button>SUBSCRIBE</button> */}
                            {/* </div> */}
                        </div>
                    </div>
                    {/* Additional slider items */}
                    <div className="item">
                        <img src="background1.jpg" alt="Slide 2" />
                        <div className="content">
                            <div className="author">VISIT LAMU</div>
                            <div className="title">Travel, Discover,</div>
                            <div className="topic">Explore, Adventure</div>
                            {/* <div className="des">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus.
                            </div> */}
                            {/* <div className="buttons"> */}
                            {/* <button>SEE MORE</button> */}
                            {/* <button>SUBSCRIBE</button> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="item">
                        <img src="background2.jpg" alt="Slide 2" />
                        <div className="content">
                            <div className="author">VISIT LAMU</div>
                            <div className="title">Travel, Discover,</div>
                            <div className="topic">Explore, Adventure</div>
                            {/* <div className="des">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus.
                            </div> */}
                            {/* <div className="buttons"> */}
                            {/* <button>SEE MORE</button> */}
                            {/* <button>SUBSCRIBE</button> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="item">
                        <img src="background3.jpg" alt="Slide 2" />
                        <div className="content">
                            <div className="author">VISIT LAMU</div>
                            <div className="title">Travel, Discover,</div>
                            <div className="topic">Explore, Adventure</div>
                            {/* <div className="des">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus.
                            </div> */}
                            {/* <div className="buttons"> */}
                            {/* <button>SEE MORE</button> */}
                            {/* <button>SUBSCRIBE</button> */}
                            {/* </div> */}
                        </div>
                    </div>
                    {/* Add more items as needed */}
                </div>

                {/* Thumbnail Section */}
                <div className="thumbnail" style={{display:'none'}}>
                    <div className="item">
                        <img src="background.jpg" alt="Thumbnail 1" />
                        <div className="content">
                            <div className="title">Lamu Old Town</div>
                            
                        </div>
                    </div>
                    <div className="item">
                        <img src="background1.jpg" alt="Thumbnail 2" />
                        <div className="content">
                            <div className="title">Lamu Old Town</div>
                            
                        </div>
                    </div>
                    <div className="item">
                        <img src="background2.jpg" alt="Thumbnail 2" />
                        <div className="content">
                            <div className="title">Lamu Old Town</div>
                            
                        </div>
                    </div>
                    <div className="item">
                        <img src="background.jpg" alt="Thumbnail 1" />
                        <div className="content">
                            <div className="title">Lamu Old Town</div>
                            
                        </div>
                    </div>
                    <div className="item">
                        <img src="background1.jpg" alt="Thumbnail 2" />
                        <div className="content">
                            <div className="title">Lamu Old Town</div>
                            
                        </div>
                    </div>
                    <div className="item">
                        <img src="background2.jpg" alt="Thumbnail 2" />
                        <div className="content">
                            <div className="title">Lamu Old Town</div>
                            
                        </div>
                    </div>
                </div>

                {/* Navigation Arrows */}
                <div className="arrows">
                    <button id="prev">&lt;</button>
                    <button id="next">&gt;</button>
                </div>

                {/* Time Indicator */}
                <div className="time"></div>
            </div>





            <section id="about" className="about section">
                <div className="container section-title" data-aos="fade-up">
                    <h2>About</h2>
                </div>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <img
                                src="/about.jpg"
                                className="img-fluid"
                                alt="About"
                                style={{ width: '80%', height: '70%', borderRadius: '10px', transition: 'transform 0.5s' }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            />
                            <br />
                            <a href="/about" className="read-more mt-3"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                        </div>
                        <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                            <p>Visit Lamu is a premier Tour & Travel Company nestled in the heart of Lamu, Kenya. we specialize in curating unforgettable travel experiences for adventurers from across the globe, allowing them to discover and explore Lamu and its surrounding areas.</p>
                            <p>As the ultimate holiday experts, you can entrust your travel plans to us with confidence, knowing that you're in the most capable hands. Our team has traversed every corner of this breathtaking region, enabling us to craft the perfect holiday tailored to your preferences. From Guided Tours and Short Breaks to Day Tours, we offer a diverse array of holiday options to cater to every traveler's desires.</p>
                            <p>Embark on a journey with Visit Lamu and let us transform your travel dreams into unforgettable realities.</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* Services Section */}
            <section id="services" className="services section" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container section-title" data-aos="fade-up">
                    <h2>Lamu Day Trips & Excursions</h2>
                </div>
                <div className="container">
                    <div className="row gy-4">
                        {[
                            {
                                image: "/lamu_dhow.jpg",
                                title: "Sunset Dhow Cruise",
                                description:
                                    "Experience the magic of Lamu's stunning sunsets aboard a traditional dhow. Sail along the tranquil waters of the Indian Ocean as the sun dips below the horizon, painting the sky in a kaleidoscope of colors. Relax, unwind, and create memories that will last a lifetime.",
                            },
                            {
                                image: "/archipelago.jpg",
                                title: "Lamu Archipelago Tour",
                                description:
                                    "Embark on an unforgettable journey through the Lamu Archipelago, a pristine paradise waiting to be explored. Our tour offers captivating adventures through charming fishing villages, secluded beaches, and turquoise waters aboard traditional dhows. With opportunities for snorkeling, diving, and wildlife spotting, join us to uncover the hidden treasures of this idyllic island paradise.",
                            },
                        ].map((service, index) => (
                            <div key={index} className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay={(index + 1) * 200}>
                                <div className="service-item position-relative">
                                    <div className="image">
                                        <img src={service.image} alt={service.title} className="img-fluid service-image" />
                                    </div>
                                    <a href="/" className="stretched-link service-link">
                                        <h3>{service.title}</h3>
                                    </a>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section id="about" className="about section">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Accomodations In Lamu</h2>
                    {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> */}
                </div>

                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <img
                                src="/accomodations.jpg"
                                className='img-fluid'
                                alt="Accommodation"
                                style={{ width: '80%', height: '70%' }}
                            />
                            <br />

                            <a href="/services" className="read-more p-3 mt-3"><span>Explore our Services</span><i className="bi bi-arrow-right"></i></a>
                        </div>
                        <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                            <p>Accomodation in Lamu is your gateway to the mesmerizing beauty of
                                Lamu's Swahili architectural home design: Embark on a journey with
                                us to discover the unparalleled charm of Lamu's traditional
                                architecture.
                            </p>
                            <p>
                                Guided by our expert team, immerse yourself in the intricate
                                carvings and coral stone walls that define this UNESCO World
                                Heritage site.
                            </p>
                            <p>
                                Experience the ultimate relaxation by indulging in our
                                exclusive swimming pool, where the enchanting fragrance of
                                "Asumini" roses fills the air, creating an unforgettable
                                sensory escape amidst Lamu's timeless allure.


                            </p>
                        </div>


                    </div>
                </div>

            </section>
            <div
                className='reviews-area'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    textAlign: 'center'
                }}
            >
                <span style={{ fontSize: '60px', color: 'red', padding: '20px' }}>
                    Travel,</span>
                <span style={{ fontSize: '60px', color: 'white', padding: '20px' }}>
                    Discover,</span>
                <span style={{ fontSize: '60px', color: 'red', padding: '20px' }}>
                    Explore,</span>
                <span style={{ fontSize: '60px', color: 'white', padding: '20px' }}>
                    Adventure</span>

            </div>
            {/* Probably implement later parralax effect on other pages maybe */}


        </main >
    );
};

export default MainContent;
