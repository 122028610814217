import React, { useState, useEffect } from 'react';
import { MenuItem, Select, InputLabel, FormControl, TextField, Button, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const services = [    
    { name: 'Sunset Dhow Cruise', price: 25 },
    { name: 'UNESCO Old Town Tour', price: 15 },
    { name: 'Donkey Ride', price: 15 },
    { name: 'Swahili Heena Painting', price: 13 },
    { name: 'Blue Safari', price: 175 },
    { name: 'Outdoor Photography', price: 30 },
    { name: 'Swahili Cooking Class', price: 50 },
];

const excursions = [
    { name: 'Taqwa Ruins Tour', price: 50 },
    { name: 'Snorkelling', price: 150 },
    { name: 'Sand Dunes', price: 10 },
    { name: 'Lamu Archipelago Tour', price: 130 },
];

const Book = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('254');
    const [price, setPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [message, setMessage] = useState('');
    const [numPeople, setNumPeople] = useState(1);
    const [exchangeRate, setExchangeRate] = useState(0);

    const clientId = 'AQhXCqlFuFhqb6ko4OjBwGXCTS24w7fLrBmBbTwkBjR6SYnitz8wt2FRujHm68PH-yMiyoPCRVH9A-aN';
    const initialOptions = {
        'client-id': clientId,
        currency: 'USD',
    };

    // Fetch exchange rate on component mount
    useEffect(() => {
        fetch('https://api.exchangerate-api.com/v4/latest/USD')
            .then((response) => response.json())
            .then((data) => {
                setExchangeRate(data.rates.KES);
            })
            .catch((error) => console.error('Error fetching exchange rate:', error));
    }, []);

    const handleOptionChange = (event) => {
        const { value } = event.target;
        const selected = [...services, ...excursions].find(option => option.name === value);
        setSelectedOption(value);
        const basePrice = selected ? selected.price : 0;
        setPrice(basePrice);
        setTotalPrice(basePrice * numPeople);
    };

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);

        // Update the price based on the payment method
        if (event.target.value === 'mpesa' && exchangeRate) {
            setTotalPrice(price * numPeople * exchangeRate);
        } else {
            setTotalPrice(price * numPeople);
        }
    };

    const handleNumPeopleChange = (event) => {
        const number = parseInt(event.target.value, 10);
        setNumPeople(number);

        // Recalculate total price
        if (paymentMethod === 'mpesa' && exchangeRate) {
            setTotalPrice(price * number * exchangeRate);
        } else {
            setTotalPrice(price * number);
        }
    };

    const handleSubmit = () => {
        if (!selectedOption || !name || !paymentMethod || !selectedDate) {
            setMessage("Please fill in all required fields.");
            return;
        }
    
        const payload = {
            amount: totalPrice,
            phoneNumber,
            name,
            serviceOrExcursion: selectedOption,
            date: selectedDate,
            numPeople,
        };

        if (paymentMethod === 'mpesa') {
            fetch('https://node.visitlamu.co/api/push-request', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(data => {
                    console.log("STK Push Response:", data);
                    setMessage(data.success ? "M-Pesa payment successful!" : "M-Pesa payment failed.");
                })
                .catch(() => setMessage("Failed to initiate M-Pesa payment."));
        } else if (paymentMethod === 'paypal') {
            // For PayPal, only send an email as the payment flow is handled externally
            fetch('https://node.visitlamu.co/api/send-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    to: email,
                    subject: `Booking Confirmation for ${selectedOption}`,
                    body: `Hello ${name},\n\nThank you for booking ${selectedOption} on ${selectedDate.toLocaleDateString()}.\n\nTotal price is ${totalPrice}.\n\nWe look forward to serving you!\n\nBest regards,\nVisit Lamu Team`,
                }),
            })
                .then(response => response.json())
                .then(() => setMessage("Booking successful! A confirmation email has been sent."))
                .catch(() => setMessage("Booking successful, but failed to send email."));
        }
    };

    return (
        <main className="main">
            <div className="page-title dark-background" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container position-relative">
                    <h1>Book</h1>
                </div>
            </div>

            <div className="container card">
                <h2>Book a Service or Excursion</h2>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                />

                <div className="col-12" style={{ zIndex: '99999' }}>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        className="form-control"
                        placeholderText="Select Service Date"
                        minDate={new Date()}
                    />
                </div>

                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Service or Excursion</InputLabel>
                    <Select value={selectedOption} onChange={handleOptionChange}>
                        <Typography variant="subtitle1" color="textSecondary" style={{ marginLeft: 10, fontWeight: 'bold' }}>Services</Typography>
                        {services.map((service) => (
                            <MenuItem key={service.name} value={service.name}>
                                {service.name} - {service.price} USD
                            </MenuItem>
                        ))}
                        <Typography variant="subtitle1" color="textSecondary" style={{ marginLeft: 10, fontWeight: 'bold' }}>Excursions</Typography>
                        {excursions.map((excursion) => (
                            <MenuItem key={excursion.name} value={excursion.name}>
                                {excursion.name} - {excursion.price} USD
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Number of People</InputLabel>
                    <Select value={numPeople} onChange={handleNumPeopleChange}>
                        {[...Array(10).keys()].map((num) => (
                            <MenuItem key={num + 1} value={num + 1}>
                                {num + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Payment Method</InputLabel>
                    <Select value={paymentMethod} onChange={handlePaymentChange}>
                        <MenuItem value="paypal">PayPal</MenuItem>
                        <MenuItem value="mpesa">M-Pesa</MenuItem>
                    </Select>
                </FormControl>

                {paymentMethod === 'mpesa' && (
                    <PhoneInput
                        country={'ke'}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                        inputStyle={{ width: '100%', marginTop: '16px', borderRadius: '4px' }}
                    />
                )}

                {paymentMethod === 'paypal' && (
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                            style={{ layout: 'vertical' }}
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [{ amount: { value: totalPrice } }],
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then(() => {
                                    setMessage("Payment successful via PayPal!");
                                });
                            }}
                        />
                    </PayPalScriptProvider>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={!selectedOption || !name || !paymentMethod}
                    style={{ marginTop: '16px' }}
                >
                    Submit Booking
                </Button>
                {message && <p>{message}</p>}
            </div>
        </main>
    );
};

export default Book;
